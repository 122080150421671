<template>
  <f-card>
    TAGS
    <v-data-table :headers="headers" :items="tags">
      <template v-slot:[`item.action`]="{ item }" class="ap-btns-icons">
        <div
          :class="{
            waiting: item.state == 0,
            loading: item.state == 1,
            approved: item.state == 2,
          }"
        >
          <button class="btn-approval none" @click="remove(item)">
            <v-icon>mdi-close</v-icon>
          </button>
        </div>
      </template>
    </v-data-table>
  </f-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t('pages.mkt.items.tags.list.tag'),
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: this.$t('pages.mkt.items.tags.list.action'),
          align: "end",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  computed: {
    tags() {
      return this.$store.state.tags.list;
    },
    loading() {
      return !this.$store.state.system.loading.done;
    },
  },
  methods: {
    edit(language) {
      console.log("TODO: editar");
      // this.$router.push({
      // 	name: 'settings-job-roles-edit',
      // 	params: {
      // 		id: jobRole.id
      // 	}
      // })
    },
    async remove(item) {
      var response = await this.$store.dispatch("dialog/show", {
        title: this.$t('labels.remove'),
        content: this.$t('alerts.removeTag'),
      });
      if (response) {
        await this.$store.dispatch("tags/remove", item.id);
        await this.$store.dispatch("tags/list");
      }
    },
  },
};
</script>
